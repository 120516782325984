export const productUtils = {
    formatProductTableData: (data) => {
        let tableData = {}
        data.variants.forEach(variant => {
            tableData[variant['product-naam']] = tableData[variant['product-naam']] ? tableData[variant['product-naam']] + 1 : 1
        })
        const productNames = Object.keys(tableData)
        const formattedTableData = productNames.map((name, index) => [index + 1, name, tableData[name]])
        return formattedTableData
    }
}