import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainForm2 } from "./components/MainForm2";
import { useState } from "react";
import Layout from "./components/layout/Layout";
import Home from "./routes/home/Home";
import Summary from "./routes/summary/Summary";

function App() {
    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='samenvatting' element={<Summary />} />
            </Route>
            </Routes>
        </BrowserRouter>  
    );
}

export default App;
