export const variantConfig = {
    defaultFormFields: {
        'product-naam': '',
        'artikel-naam': '',
        'ean': '',
        'aantallen': '',
        'aantal-dozen': '',
        'gewicht': '',
        'length': '',
        'width': '',
        'height': '',
        'opmerking': '',
    },
    formFields: [
        {
            name: 'Naam artikel:',
            id: 'artikel-naam',
            type: 'text',
            placeholder: 'bijv. Groen'
        },
        {
            name: 'EAN:',
            id: 'ean',
            type: 'select',
            placeholder: 'bijv. 123456'
        },
        {
            name: 'Totaal aantallen:',
            id: 'aantallen',
            type: 'text',
            placeholder: 'bijv. 10'
        },
        {
            name: 'Aantal dozen:',
            id: 'aantal-dozen',
            type: 'text',
            placeholder: 'bijv. 10'
        },
        {
            name: 'Gewicht (g) per artikel:',
            id: 'gewicht',
            type: 'text',
            placeholder: 'bijv. 500g'
        },
        {
            name: 'Afmetingen per product:',
            dimensions: [
                {
                    name: 'L:',
                    id: 'length',
                    type: 'text',
                    placeholder: ''
                },
                {
                    name: 'B:',
                    id: 'width',
                    type: 'text',
                    placeholder: ''
                },
                {
                    name: 'H:',
                    id: 'height',
                    type: 'text',
                    placeholder: ''
                },
            ]
        },
    ]
}
