import React, { useState } from 'react'
import { productUtils } from './utils'

function Products({stateConfig}) {
    const [showTable, setShowTable] = useState(true)
    const {
        allFormData, 
        setAllFormData, 
        newProductName,
        setNewProductName,
        setClientName,
        clientName
    } = stateConfig

    const handleRemoveItem = (index) => {
        if(allFormData.products.length <= 1) {
            setAllFormData(prevData => {
                const newFormData = {
                    variants: prevData.variants
                }
                return newFormData
            })
            return
        }
        const newProducts = allFormData.products.splice(index, 1)
        setAllFormData(prevData => {
            const newFormData = {
                variants: prevData.variants
            }
            return newFormData
        })
    }

    return (
        <div className='relative w-full h-full flex justify-between'>
            <div className='grid grid-cols-2 gap-x-4 gap-y-2'>
                <p className='text-tumfur-green text-lg italic col-span-2'>Algemeen</p>
                <span className='flex justify-between w-full'>
                    <label htmlFor='client-name' className=''>Klant naam:</label>
                    <input 
                        className='border-2 border-tumfur-main px-2 rounded-md mx-2'
                        onChange={e => setClientName(e.target.value)}
                        type='text' 
                        id='client-name' 
                        name='client-name' />
                </span>
                <span className='flex justify-between w-full'>
                    <label htmlFor='reference' className=''>Referentie:</label>
                    <input 
                        className='border-2 border-tumfur-main px-2 rounded-md mx-2'
                        onChange={e => setAllFormData(prev => {
                            const newData = {reference: e.target.value}
                            return {
                                ...prev,
                                ...newData
                            }
                        })}
                        value={allFormData.reference}
                        type='text' 
                        id='reference' 
                        name='reference' />
                </span>
                <span className='flex justify-between w-full'>
                    <label htmlFor='product-name' className=''>Product naam:</label>
                    <input 
                        className='border-2 border-tumfur-main px-2 rounded-md mx-2'
                        onChange={e => setNewProductName(e.target.value)}
                        value={newProductName}
                        type='text' 
                        id='product-name' 
                        name='product-name' />
                </span>
            </div>
            <div className='absolute right-4 top-0 flex flex-row-reverse gap-x-4'>
                <button 
                    onClick={(() => setShowTable(!showTable))}
                    className='text-lg font-semibold w-fit h-20 border-2 border-tumfur-green rounded-lg shadow-lg mr-0 ml-auto mb-4 p-4'>Product overzicht {showTable ? '-' : '+'}</button>
                {showTable && allFormData.variants.length > 0 && <ProductTable data={allFormData} />}
            </div>
        </div>
    )
}

function ProductTable({data}) {
    const formattedData = productUtils.formatProductTableData(data)
    return (
        <table className='bg-white shadow-lg border-2 border-tumfur-main'>
            <thead className='border-b-2 border-tumfur-main bg-tumfur-green text-white'>
                <tr className='font-semibold'>
                    <th className='px-2'>#</th>
                    <th className='px-2'>Product</th>
                    <th className='px-2'>Aantal varianten</th>
                </tr>
            </thead>
            <tbody>
                {data.variants.length > 0 && formattedData.map((row, index) => {
                    return (
                        <tr key={index} className='border-b-2 border-tumfur-green'>
                            {row.map((cell, index) => {
                                return <td key={1000 + index} className={`p-2 ${index === 0 ? 'font-semibold text-tumfur-green' : ''}`}>{cell}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default Products