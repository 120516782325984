import React, {useState} from 'react'
import {variantConfig} from './utils/variantConfig'
import {variantHelpers} from './utils/variantHelpers'

function Variants({stateConfig, defaultEan}) {
    const [addingVariant, setAddingVariant] = useState(false)
    const [newVariant, setNewVariant] = useState(variantConfig.defaultFormFields)

    const {
        allFormData, 
        setAllFormData, 
        newProductName,
        setNewProductName,
        setClientName,
        clientName,
        existingVariants
    } = stateConfig

    const variantStates = {
        setAllFormData: setAllFormData,
        setAddingVariant: setAddingVariant,
        newVariant: newVariant,
        setNewVariant: setNewVariant,
        clientName: clientName,
        newProductName: newProductName,
        existingVariants: existingVariants
    }

    const addProduct = () => {
        console.log(newProductName)
        setAllFormData(prevData => ({
            variants: [...prevData.variants]
        }))
        console.log(allFormData)
    }
    
    const handleSaveProduct = () => {
        setAddingVariant(false)
        setNewProductName('')
    }

    return (
        <div>
            {
                addingVariant ?
                    <VariantForm variantStates={variantStates} defaultEan={defaultEan}/>
                    : (
                        <div className='flex gap-x-2'>
                            <button
                                className='bg-neutral-100 h-8 px-2 rounded-lg border-tumfur-main border-2 shadow-lg hover:bg-white hover:text-tumfur-green' 
                                disabled={newProductName === ''}
                                onClick={() => setAddingVariant(true)}>+ Nieuw artikel</button>
                            <button
                                className='bg-tumfur-green h-8 px-2 rounded-lg border-tumfur-main border-2 text-white shadow-lg hover:bg-tumfur-green/90' 
                                onClick={handleSaveProduct}>Product opslaan</button>
                        </div>
                    )
            }
        </div>
    )
}

function VariantForm({variantStates, defaultEan}) {
    const [addNewEan, setAddNewEan] = useState(false)

    const {defaultFormFields, formFields} = variantConfig
    const {setAddingVariant, newVariant, setNewVariant, setAllFormData, newProductName, existingVariants} = variantStates
    const {createFormField} = variantHelpers

    const handleAddVariant = () => {
        newVariant['product-naam'] = newProductName
        const ean = newVariant['ean'] === '' ? defaultEan : newVariant['ean']
        newVariant['ean'] = ean
        setAllFormData(prevData => ({
            ...prevData,
            ...{variants: [...prevData.variants, newVariant]}
        }))
        setAddingVariant(false)
        setNewVariant(defaultFormFields)
    }

    return (
        <div className='w-3/4 max-h-[25rem] overflow-hidden border-t-2 border-tumfur-green py-4 mt-4'>
            <p className='text-lg italic text-tumfur-green'>Artikel info</p>
            <div className='my-4 grid grid-cols-2 h-fit gap-x-4 '>
                {formFields && existingVariants && formFields.map((formField, index) => createFormField(formField, newVariant, setNewVariant, index, existingVariants, addNewEan, setAddNewEan))}
            </div>
            <div className='flex w-full justify-between gap-x-4 h-8 mt-0'>
                <button 
                    className='bg-neutral-100 px-2 rounded-lg border-tumfur-main border-2 shadow-lg hover:bg-white hover:text-tumfur-green'
                    onClick={() => setAddingVariant(false)}>Terug</button>
                <button
                    className='bg-tumfur-green px-10 rounded-lg border-tumfur-main border-2 text-white shadow-lg hover:bg-tumfur-green/90' 
                    onClick={handleAddVariant}>Toevoegen</button>
            </div>
        </div>
    )
}

export default Variants