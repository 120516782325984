import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import LoadingGif from '../../images/loading.gif'

// TEST_URL = http://localhost:3000/samenvatting/%7B%22reference%22:%2212345%22,%22variants%22:[%7B%22product-naam%22:%22Test1%22,%22artikel-naam%22:%22groen%22,%22ean%22:%22123456%22,%22aantallen%22:%2210%22,%22aantal-dozen%22:%2210%22,%22gewicht%22:%22500%22,%22length%22:%2210%22,%22width%22:%2210%22,%22height%22:%2210%22,%22opmerking%22:%22%22%7D,%7B%22product-naam%22:%22Test1%22,%22artikel-naam%22:%22Blauw%22,%22ean%22:%22123457%22,%22aantallen%22:%221%22,%22aantal-dozen%22:%221%22,%22gewicht%22:%221%22,%22length%22:%221%22,%22width%22:%221%22,%22height%22:%221%22,%22opmerking%22:%22%22%7D,%7B%22product-naam%22:%22Test2%22,%22artikel-naam%22:%22blauw%22,%22ean%22:%22123458%22,%22aantallen%22:%2210%22,%22aantal-dozen%22:%2210%22,%22gewicht%22:%22500%22,%22length%22:%221%22,%22width%22:%221%22,%22height%22:%221%22,%22opmerking%22:%22%22%7D]%7D/jason

function Summary() {
    const [data, setData] = useState()
    const [clientName, setClientName] = useState()
    const [variants, setVariants] = useState()
    const [reference, setReference] = useState()
    const [uploadingProduct, setUploadingProduct] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)

    const {state} = useLocation()

    useEffect(() => {
        const {allFormData, clientName} = state
        const {reference, variants} = allFormData
        setReference(reference)
        setVariants(variants)
        setClientName(clientName)

        const formattedData = formatProducts(variants) 
        setData(formattedData)
    }, [state])


    const formatProducts = (data) => {
        const productNames = Array.from(new Set(data.map(variant => variant['product-naam'])))
        const products = productNames.reduce((a, v) => ({ ...a, [v]: [] }), {})
        for (let i = 0; i < data.length; i++) {
            const singleProduct = data[i]
            products[singleProduct['product-naam']].push(singleProduct)
        }
        return products
    }

    const submitForm = () => {
        setUploadingProduct(true)
        const prodUrl = 'https://7qontvhb3pbzciegmtsuejxgda0xfvjt.lambda-url.us-east-1.on.aws'
        const devUrl = 'http://localhost:3001'
        axios.post(`${prodUrl}/send-queue-message`, { clientName: clientName, messageBody: variants, reference: reference })
        .then(() => {
            setUploadingProduct(false)
            setUploadSuccess(true)
        })
    }
    return (
        <div className={`px-8 py-10 w-full h-full `}>
            <div className={`w-4/5 mx-auto bg-white rounded-lg p-8 shadow-xl ${uploadSuccess ? 'opacity-80' : ''}`}>
                <h1 className='text-2xl font-semibold mb-6'>Aanmeldformulier overzicht</h1>
                <div className='w-40 h-fit'>
                    <table className='mb-8 w-full h-full'>
                        <tbody>
                            <tr className='border-2 border-black'>
                                <td className='p-2 bg-tumfur-green/70 text-white border-r-2 border-tumfur-main'>Referentie</td>
                                <td className='p-2 bg-white'>{reference}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {data && Object.keys(data).map((productName, index) => {
                    return (
                        <div className='w-1/2 h-fit my-8' key={index}>
                            <p className='text-center text-xl font-semibold'>{productName}</p>
                            <table className='w-full h-full'>
                                <thead>
                                    <tr className='border-2 border-black'>
                                        <th className='bg-tumfur-green/70 text-white'>Artikel</th>
                                        <th className='bg-tumfur-green/70 text-white'>EAN</th>
                                        <th className='bg-tumfur-green/70 text-white'># Producten</th>
                                        <th className='bg-tumfur-green/70 text-white'># Dozen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[productName].map((variant, index) => {
                                        const rowColor = index % 2 === 0 ? 'bg-gray-200' : 'bg-white'
                                        return (
                                            <tr key={1000 + index} className={`border-2 border-black ${rowColor}`}>
                                                <td className='text-center'>{variant['artikel-naam']}</td>
                                                <td className='text-center'>{variant['ean']}</td>
                                                <td className='text-center'>{variant['aantallen']}</td>
                                                <td className='text-center'>{variant['aantal-dozen']}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )
                })}
                <button
                    onClick={submitForm}
                    disabled={variants && variants.length <= 0 || uploadingProduct}
                    className='uppercase font-bold bg-tumfur-green w-full h-16 my-8 mx-auto text-white border-2 border-tumfur-main rounded-md hover:rounded-sm hover:text-gray-300'>
                    {uploadingProduct ? 
                        <img src={LoadingGif} alt='loading' className='h-16 mx-auto'></img> 
                        : 'formulier opsturen'}
                </button>
            </div>
            {uploadSuccess &&
                <div className='absolute top-[30vh] left-[30vw] w-[40vw] h-fit bg-tumfur-main shadow-md rounded-md mx-auto text-white py-8 px-10'>
                    <p className='text-center text-2xl font-bold uppercase shadow-xl'>Formulier is verzonden !</p>
                </div>
            }
        </div>
    )
}

export default Summary