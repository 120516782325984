import React, {useEffect, useState} from 'react'
import Products from '../../components/products/Products'
import Variants from '../../components/variants/Variants'
import {homeConfig} from './utils/homeConfig'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function Home() {
    const [allFormData, setAllFormData] = useState(homeConfig.formDataTemplate)
    const [defaultEan, setDefaultEan] = useState('')
    const [existingVariants, setExistingVariants] = useState([])
    const [newProductName, setNewProductName] = useState('')
    const [clientName, setClientName] = useState('')
    const stateConfig = {
        allFormData: allFormData,
        setAllFormData: setAllFormData,
        newProductName: newProductName,
        setNewProductName: setNewProductName,
        setClientName: setClientName,
        clientName: clientName,
        existingVariants: existingVariants,
        defaultEan: defaultEan
    }
    
    useEffect(() => {
        const prodUrl = 'https://7qontvhb3pbzciegmtsuejxgda0xfvjt.lambda-url.us-east-1.on.aws'
        const res = axios.get(prodUrl + '/variant-eans').then(res => {
            const {recordset} = JSON.parse(res.data.data)
            setExistingVariants(recordset)
            setDefaultEan(recordset[0].EAN)
        })
    }, [])

    const navigate = useNavigate()

    return (
        <div className='w-full h-full p-8'>
            <div className='bg-white rounded-md p-6 shadow-lg flex flex-col gap-y-4'>
                <h1 className='text-xl underline underline-offset-4 font-semibold'>Nieuw aanmeldformulier</h1>
                <Products stateConfig={stateConfig} />
                <Variants stateConfig={stateConfig} defaultEan={defaultEan}/>
                <button 
                    onClick={() => navigate(encodeURI(`/samenvatting`), {state: {allFormData: allFormData, clientName: clientName}})}
                    className='bg-tumfur-green text-white rounded-lg border-2 border-tumfur-main hover:bg-tumfur-green/80'>Afronden</button>
            </div>
        </div>
    )
}

export default Home