export const variantHelpers = {
    createFormField: (formField, allData, setAllData, index, existingVariants, addNewEan, setAddNewEan) => {
        if(formField.dimensions?.length > 0) {
            return (
                <span className='flex flex-row justify-between my-2 items-center' key={formField.name + '-' + index}>
                    <label htmlFor='dimensions'>{formField.name}</label>
                    <span className='flex'>
                        {formField.dimensions.map(dimension => {
                            return (
                                <span key={dimension.id} className='flex flex-row justify-between w-fit items-center'>
                                    <label className='mx-2' htmlFor={dimension.id}>{dimension.name}</label>
                                    <input
                                        className='p-2 w-12 h-8 text-black border-2 border-tumfur-main rounded-md'
                                        type='text'
                                        onChange={e => setAllData(prevData => {
                                            const newItem = {}
                                            newItem[dimension.id] = e.target.value
                                            return {
                                                ...prevData,
                                                ...newItem
                                            }
                                        })}
                                        placeholder=''
                                        value={allData[dimension.id] ?? ''}
                                        id={dimension.id}
                                        name={dimension.id}></input>
                                </span>
                            )
                        })}
                    </span>
                </span>
            )
        }

        if(formField.type === 'select') {
            return (
                <span className='flex flex-row justify-between my-2 h-fit items-center' key={formField.id}>
                    <label htmlFor={formField.id} className='w-fit'>{formField.name}</label>
                    <button 
                        onClick={() => {
                            setAddNewEan(!addNewEan)
                            setAllData(prev => {
                                const newItem = {}
                                newItem['ean'] = ''
                                return {
                                    ...prev,
                                    ...newItem
                                }
                            })
                        }}
                        className='border-2 border-tumfur-main px-2 bg-tumfur-green text-white rounded-lg mr-2 ml-auto'>Nieuwe EAN +</button>
                    {
                        !addNewEan ?
                            <select value={allData[formField.id]}
                                onChange={e => handleEANChange(e, setAllData, formField, existingVariants)} 
                                id={formField.Id}
                                key={formField.id} className='w-fit border-2 border-tumfur-main px-4'>
                                {existingVariants.map((variant, index) => {
                                    // console.log(variant)
                                    return (
                                        <option key={index} name={variant.ean} id={variant.ean}>{`${variant.EAN}`}</option>
                                    )
                                })}
                            </select>
                            : <input
                                className='p-2 h-8 text-black border-2 border-tumfur-main rounded-md'
                                type='text'
                                onChange={e => setAllData(prevData => {
                                    const newItem = {}
                                    newItem[formField.id] = e.target.value
                                    return {
                                        ...prevData,
                                        ...newItem
                                    }
                                })}
                                placeholder={formField.placeholder ?? ''}
                                value={allData[formField.id] ?? ''}
                                id={formField.id}
                                name={formField.id}></input>
                    }
                </span>
            )
        }

        if(formField.type === 'text') {
            return (
                <span className='flex flex-row justify-between my-2 h-fit items-center' key={formField.id}>
                    <label htmlFor={formField.id} className='w-fit'>{formField.name}</label>
                    <input
                        className='p-2 h-8 text-black border-2 border-tumfur-main rounded-md'
                        type='text'
                        onChange={e => setAllData(prevData => {
                            const newItem = {}
                            newItem[formField.id] = e.target.value
                            return {
                                ...prevData,
                                ...newItem
                            }
                        })}
                        placeholder={formField.placeholder ?? ''}
                        value={allData[formField.id] ?? ''}
                        id={formField.id}
                        name={formField.id}></input>
                </span>
            )
        }

        if(formField.type === 'textarea') {
            return (
                <span className='flex flex-row justify-between' key={formField.id}>
                    <label htmlFor={formField.id}>{formField.name}</label>
                    <textarea
                        rows='5'
                        columns='10'
                        className='p-2 h-16 w-2/3 border-2 border-tumfur-main resize-none rounded-md'
                        value={allData[formField.id] ?? ''}
                        onChange={e => setAllData(prevData => {
                            const newItem = {}
                            newItem[formField.id] = e.target.value
                            return {
                                ...prevData,
                                ...newItem
                            }
                        })}
                        name={formField.id}
                        id={formField.id}></textarea>
                </span>
            )
        }

    }
}

const getVariantData = (existingVariants, ean) => {
    console.log(existingVariants, ean)
    const variants = existingVariants.filter(variant => variant.EAN === ean)
    return variants
}

const handleEANChange = (e, setAllData, formField, existingVariants) => {
    const details = getVariantData(existingVariants, e.target.selectedOptions[0].innerHTML)
    setAllData(prevData => {
        const newItem = {}
        newItem[formField.id] = e.target.selectedOptions[0].innerHTML
        return {
            ...prevData,
            ...newItem
        }
    })
}
