import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import tumfurLogo from '../../images/tumfur-logo.png'
import closeButton from '../../images/close-button.png'
import {Help} from '../Help'
import helpIcon from '../../images/help.png'

function Layout() {
    const [help, setHelp] = useState(false)

    return (
        <div className='bg-gray-200 min-h-screen'>
            <header className={`flex w-full h-24 bg-tumfur-main justify-between items-center ${help ? 'opacity-60' : ''}`}>
                <img src={tumfurLogo} className='h-16 ml-4'></img>
                <span className='flex flex-row items-center'>
                <a href='https://tumfur.nl' className='uppercase mr-8 text-white font-bold hover:text-gray-400'>Naar de website</a>
                <button onClick={() => setHelp(!help)}>
                    <img src={helpIcon} className='h-10 mr-4 '></img>
                </button>
                </span>
            </header>
            <Outlet />
            {
                help && (
                <>
                    <div className='absolute left-[25%] top-[15%] p-4 w-[50vw] h-[65vh] bg-tumfur-main'>
                    <button
                        onClick={() => setHelp(!help)}
                        className='absolute top-2 right-2 z-10 text-white'>
                        <img src={closeButton} className='h-10'></img>
                    </button>
                    <Help />
                    </div>
                </>
                )
            }
        </div>
    )
}

export default Layout