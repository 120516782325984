import { Player } from 'video-react';
import helpVideo from '../images/help-video.mov'


export const Help = () => {
    return (
        <div className='flex h-full w-full flex-col'>
            <h1 className='uppercase font-bold text-xl text-center text-white mb-8 mt-4'>bekijk de video voor instructies</h1>
            <Player>
                <source src={helpVideo} />
            </Player>
        </div>
    )
}